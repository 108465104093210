.mailchimpform {
  position: relative;
}
.mailchimpform input {
  background-color: #fff;
  border-radius: 999px;
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 3.5rem;
  padding-right: 10rem;
  color: #000;
}

.mailchimpform button {
  background-color: rgba(219, 84, 55);
  font-weight: 700;
  color: #fff;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  top: 4px;
  right: 4px;
  outline: none;
  border-radius: 999px;
}
.mailchimpform button:hover {
  background-color: rgba(239, 68, 68);
}
.mailchimpform span {
  top: 25px;
}

@media screen and (min-width: 640px) {
  .mailchimpform input {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .mailchimpform button {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
